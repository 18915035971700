// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import JobListings from './pages/JobListings';
import JobDetails from './pages/JobDetails';
import ApplicationForm from './pages/ApplicationForm';
import ContactUs from './pages/ContactUs';
import ApplicationSuccess from './pages/ApplicationSuccess';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import AboutUs from './pages/AboutUs';
import WhyCapstone from './pages/WhyCapstone';
import QuickApply from './pages/QuickApply';
import NotFound from './pages/NotFound';
import Redirects from './components/Redirects';


// Specialty pages
import Nursing from './pages/Nursing';
import LongTermCare from './pages/LongTermCare';
import CathLabs from './pages/CathLabs';
import TravelNursing from './pages/TravelNursing';
import Cardiopulmonary from './pages/Cardiopulmonary';
import Imaging from './pages/Imaging';
import Laboratory from './pages/Laboratory';
import Therapy from './pages/Therapy';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './styles/global.css';

// Layout component
const Layout = ({ children }) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};

function App() {
  return (
    <Router>
      <Navigation />
      <Redirects />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          path="/why-capstone"
          element={
            <Layout>
              <WhyCapstone />
            </Layout>
          }
        />
        <Route
          path="/jobs"
          element={
            <Layout>
              <JobListings />
            </Layout>
          }
        />
        <Route
          path="/jobs/:jobId"
          element={
            <Layout>
              <JobDetails />
            </Layout>
          }
        />
        <Route
          path="/apply/:jobId"
          element={
            <Layout>
              <ApplicationForm />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />
        <Route
          path="/application-success"
          element={
            <Layout>
              <ApplicationSuccess />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />

        {/* Specialty pages */}
        <Route
          path="/Nursing-Staffing-Services"
          element={
            <Layout>
              <Nursing />
            </Layout>
          }
        />
        <Route
          path="/Long-Term-Care-Nurse-Jobs"
          element={
            <Layout>
              <LongTermCare />
            </Layout>
          }
        />
        <Route
          path="/Cath-Labs-Technologist-Jobs"
          element={
            <Layout>
              <CathLabs />
            </Layout>
          }
        />
        <Route
          path="/Travel-Nursing-Jobs"
          element={
            <Layout>
              <TravelNursing />
            </Layout>
          }
        />
        <Route
          path="/Cardiopulmonary-Jobs"
          element={
            <Layout>
              <Cardiopulmonary />
            </Layout>
          }
        />
        <Route
          path="/Imaging-Jobs"
          element={
            <Layout>
              <Imaging />
            </Layout>
          }
        />
        <Route
          path="/Laboratory-Technologist-Jobs"
          element={
            <Layout>
              <Laboratory />
            </Layout>
          }
        />
        <Route
          path="/Therapy-Social-Worker-Jobs"
          element={
            <Layout>
              <Therapy />
            </Layout>
          }
        />
                <Route
          path="/quickapply"
          element={
            <Layout>
              <QuickApply />
            </Layout>
          }
        />

        {/* Catch-all route for anything not matched above -> Shows NotFound */}
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );

}

export default App;
