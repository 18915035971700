// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFax, FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
// Twitter icon import commented out until needed
// import { FaTwitterSquare } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #28346c;
  border-top: 1px solid #1c2548;
  padding: 3rem 2rem 2rem;
  color: #ffffff;
  font-size: 0.9rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterHeading = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #f47424;
  font-weight: 600;
  text-align: left; 
`;

const ContactItem = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    justify-content: left;
  }
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  min-width: 20px;
  color: #ffffff;
  font-size: 1rem;
`;

const FooterLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.2s;

  &:hover {
    color: #e0e0e0;
    text-decoration: underline;
  }
`;

const ExternalLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #e0e0e0;
    text-decoration: underline;
  }
`;

const CertificationContainer = styled.div`
  display: flex;
  align-items: center;
  transform: scale(1.25);
  transform-origin: top left;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    transform-origin: center top;
  }
`;

const CertificationImage = styled.img`
  height: 70px;
  width: auto;
  margin-bottom: 0.5rem;
`;

const CertificationTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const CertificationText = styled.span`
  color: #f47424;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const BottomFooter = styled.div`
  max-width: 1200px;
  margin: 2rem auto 0;
  padding-top: 1.5rem;
  border-top: 1px solid #3a477d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Copyright = styled.div`
  color: #ffffff;
  font-size: 0.85rem;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const MapContainer = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const SocialMediaLink = styled.a`
  color: #ffffff;
  font-size: 2rem;
  transition: color 0.2s;

  &:hover {
    color: #e0e0e0;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterHeading style = {{ fontSize: '.8rem' }}>CONTACT US</FooterHeading>
          <ContactItem>
            <IconWrapper>
              <FaMapMarkerAlt />
            </IconWrapper>
            <div>1540 High St Ste 101, Des Moines, IA 50309</div>
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <FaEnvelope />
            </IconWrapper>
            <a
              href="mailto:contact@capstone.net"
              style={{ color: '#ffffff', textDecoration: 'none' }}
            >
              contact@capstone.net
            </a>
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <FaPhone />
            </IconWrapper>
            <a
              href="tel:5154462266"
              style={{ color: '#ffffff', textDecoration: 'none' }}
            >
              (515) 446-2266
            </a>
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <FaFax />
            </IconWrapper>
            <div>(515) 528-7778</div>
          </ContactItem>
          
          <FooterHeading style={{ fontSize: '.8rem', marginTop: '1.5rem' }}>FOLLOW US</FooterHeading>
          <SocialMediaContainer>
            <SocialMediaLink href="https://www.facebook.com/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare />
            </SocialMediaLink>
            {/* Twitter account not available yet
            <SocialMediaLink href="#" target="_blank" rel="noopener noreferrer">
              <FaTwitterSquare />
            </SocialMediaLink>
            */}
            <SocialMediaLink href="https://www.instagram.com/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
              <FaInstagramSquare />
            </SocialMediaLink>
            <SocialMediaLink href="https://www.linkedin.com/company/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialMediaLink>
          </SocialMediaContainer>
        </FooterSection>

        <FooterSection>
          <FooterHeading></FooterHeading>
          <MapContainer>
            <a 
              href="https://www.google.com/maps/place/Capstone+Health/@41.5868812,-93.6386225,17z/data=!3m1!4b1!4m6!3m5!1s0x87ee995b5d4dc34f:0x5f8e0db320657c7e!8m2!3d41.5868812!4d-93.6386225!16s%2Fg%2F11t5dkhg02?entry=ttu&g_ep=EgoyMDI1MDMwOC4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapIframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2983.829!2d-93.6386225!3d41.5868812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ee995b5d4dc34f%3A0x5f8e0db320657c7e!2sCapstone%20Health!5e0!3m2!1sen!2sus!4v1709906225634!5m2!1sen!2sus"
                title="Capstone Health Google Map Location"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </a>
          </MapContainer>
        </FooterSection>

        <FooterSection>
        <FooterHeading style={{ fontSize: '.8rem' }}>CAPSTONE HEALTH </FooterHeading>
          <FooterLink to="/" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Home</FooterLink>
          <FooterLink to="/jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Browse Jobs</FooterLink>
          <FooterLink to="/about" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>About</FooterLink>
          <FooterLink to="/contact" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Contact Us</FooterLink>
        </FooterSection>

        <FooterSection>
          {/* Nursing Section */}
          <FooterHeading style={{ fontSize: '.8rem' }}>WHO WE SERVE </FooterHeading>
          <FooterLink to="/Nursing-Staffing-Services" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Nurses</FooterLink>
          <FooterLink to="/Travel-Nursing-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Travel Nurses</FooterLink>
          <FooterLink to="/Long-Term-Care-Nurse-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Long-Term Care</FooterLink>
          <FooterLink to="/Cath-Labs-Technologist-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Cath Labs</FooterLink>
          <FooterLink to="/Cardiopulmonary-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Cardiopulmonary</FooterLink>
          <FooterLink to="/Imaging-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Imaging</FooterLink>
          <FooterLink to="/Laboratory-Technologist-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Laboratory</FooterLink>
          <FooterLink to="/Therapy-Social-Worker-Jobs" onClick={() => window.scrollTo(0, 0)} style={{ display: "block", textAlign: "left" }}>Therapy</FooterLink>
        </FooterSection>

        <FooterSection>
          {/* Certification Section */}
          <CertificationContainer>
          <CertificationImage 
            src="/joint-commission-certification-seal.png" 
            alt="The Joint Commission Certification Seal" 
          />
            <CertificationTextBlock>
              <CertificationText>Certified by</CertificationText>
              <ExternalLink
                href="https://www.jointcommission.org/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Joint Commission
              </ExternalLink>
            </CertificationTextBlock>
          </CertificationContainer>
        </FooterSection>
      </FooterContent>

      <BottomFooter>
        <Copyright>© Capstone Health 2025</Copyright>
        <FooterLinks>
          <FooterLink
            to="/privacy-policy"
            onClick={() => window.scrollTo(0, 0)}
          >
            Privacy Policy
          </FooterLink>
          <FooterLink
            to="/terms-and-conditions"
            onClick={() => window.scrollTo(0, 0)}
          >
            Terms & Conditions
          </FooterLink>
        </FooterLinks>
      </BottomFooter>
    </FooterContainer>
  );
};

export default Footer;