// src/components/Redirects.js
import { Navigate, useLocation } from 'react-router-dom';

const redirectMap = {
  '/nursing': '/Nursing-Staffing-Services',
  '/travel-nursing': '/Travel-Nursing-Jobs',
  '/long-term-care': '/Long-Term-Care-Nurse-Jobs',
  '/cath-labs': '/Cath-Labs-Technologist-Jobs',
  '/cardiopulmonary': '/Cardiopulmonary-Jobs',
  '/imaging': '/Imaging-Jobs',
  '/laboratory': '/Laboratory-Technologist-Jobs',
  '/therapy': '/Therapy-Social-Worker-Jobs',
};

const Redirects = () => {
  const location = useLocation();
  const redirectPath = redirectMap[location.pathname];

  if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return null;
};

export default Redirects;
