// src/pages/QuickApply.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import salesforceApi from '../services/salesforceApi';
import '../CapstoneTheme.css'; // Import our theme

// Styled components using our Salesforce-inspired theme
const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 1rem;
  }
`;

const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FormCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 2rem;
`;

const CardHeader = styled.div`
  background: linear-gradient(to right, #28346c, #28346c);
  color: white;
  padding: 1.5rem;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  &::before {
    content: '↑';
    display: inline-block;
    margin-right: 8px;
  }
`;

const HeaderInstructions = styled.p`
  font-size: 0.875rem;
  opacity: 0.8;
  color: white;
  margin: 0;
`;

const CardBody = styled.div`
  padding: 1.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #54698d;
`;

const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 2px;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  color: #16325c;
  transition: border 0.2s ease;

  &:focus {
    outline: none;
    border-color: #0070d2;
    box-shadow: 0 0 0 1px #0070d2;
  }
`;

const FileDropArea = styled.div`
  position: relative;
  padding: 2rem;
  border: 2px dashed #d8dde6;
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s ease;
  background-color: white;
  cursor: pointer;
  
  &:hover {
    border-color: #28346c;
    background-color: #f4f6f9;
  }
  
  &.has-file {
    border-color: #04844b;
    background-color: #f3faf3;
  }
`;

const FileMsg = styled.span`
  font-size: 0.875rem;
  color: #54698d;
  margin-bottom: 0.5rem;
  display: block;
`;

const FileName = styled.span`
  font-weight: 500;
  color: #04844b;
  word-break: break-all;
`;

const FileInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: #e53e3e;
  margin-top: 0.25rem;
  font-size: 0.875rem;
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  margin: 0 auto;
  background-color: #28346c;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #005fb2;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  background-color: #c6f6d5;
  color: #2f855a;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.div`
  background-color: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const CardFooter = styled.div`
  background: #f7f9fb;
  padding: 1rem 1.5rem;
  border-top: 1px solid #eef1f6;
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  color: #28346c;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const PageDescription = styled.p`
  color: #54698d;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const OptionalText = styled.span`
  color: #718096;
  font-weight: normal;
  font-style: italic;
  margin-left: 5px;
  font-size: 0.8rem;
`;

const HelpText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: #54698d;
  margin-top: 1.5rem;
  
  a {
    color: #0070d2;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  &::before {
    content: '?';
    display: inline-block;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    background: #54698d;
    color: white;
    font-size: 12px;
  }
`;

// Checkbox container for receiving updates
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-left: .5rem;
`;

const SmallGreyText = styled.p`
  color: #7f8c8d;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  line-height: 1.4;
`;

// Phone formatter (same as ContactUs component)
const formatPhoneNumber = (value) => {
  if (!value) return value;
  
  // Remove all non-digits
  const phoneNumber = value.replace(/[^\d]/g, '');
  
  // Take only the first 10 digits
  const phoneNumberTruncated = phoneNumber.substring(0, 10);
  
  // Apply formatting based on length
  if (phoneNumberTruncated.length < 4) {
    return phoneNumberTruncated;
  } else if (phoneNumberTruncated.length < 7) {
    return `(${phoneNumberTruncated.slice(0, 3)}) ${phoneNumberTruncated.slice(3)}`;
  }
  return `(${phoneNumberTruncated.slice(0, 3)}) ${phoneNumberTruncated.slice(3, 6)}-${phoneNumberTruncated.slice(6, 10)}`;
};

// Validation schema
const quickApplySchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^\(\d{3}\) \d{3}-\d{4}$|^\d{10}$|^\(\d{3}\) \d{3}$|^\(\d{3}\)$|^\d{3}$|^\d{6}$|^\(\d{3}\) \d{3}-\d{1,3}$/, 
      'Please enter a valid phone number')
    .test('len', 'Phone number must be exactly 10 digits', 
      value => !value || value.replace(/[^\d]/g, '').length === 10 || value.replace(/[^\d]/g, '').length < 10)
});

const QuickApply = () => {
  const navigate = useNavigate();
  const [resume, setResume] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(true);

  const handleResumeChange = (event) => {
    const file = event.currentTarget.files[0];
    setResume(file);
  };

  // Handle email updates checkbox change
  const handleReceiveUpdatesChange = (e) => {
    setReceiveUpdates(e.target.checked);
  };

  const handleSubmit = async (values, { resetForm, setSubmitting: setFormikSubmitting }) => {
    setError('');
    
    try {
      setSubmitting(true);
      
      // Build the application data object based on whether a resume is provided
      const applicationData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        receiveUpdates: receiveUpdates,
        hasResume: !!resume // Flag to indicate if resume was provided
      };
      
      // If a resume is provided, validate it
      if (resume) {
        if (resume.size > 5 * 1024 * 1024) { // 5MB limit
          throw new Error('Resume file is too large (max 5MB)');
        }
        
        const allowedTypes = [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        if (!allowedTypes.includes(resume.type)) {
          throw new Error('Resume must be a PDF or Word document');
        }
      }
      
      // Submit the application - salesforceApi.submitJobApplication handles both paths
      await salesforceApi.submitJobApplication(applicationData, resume);
      
      // Scroll to the top of the page
      window.scrollTo(0, 0);
      
      // Handle success
      setSuccess(true);
      resetForm();
      setResume(null);
      
      // Redirect after 3 seconds
      setTimeout(() => {
        navigate('/application-success');
      }, 3000);
    } catch (err) {
      // Also scroll to top on error to make the error message visible
      window.scrollTo(0, 0);
      setError(err.message || 'Failed to submit application. Please try again.');
    } finally {
      setSubmitting(false);
      setFormikSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <PageTitle>Quick Apply to Capstone</PageTitle>
      <PageDescription>
        Submit your information to be considered for future opportunities
      </PageDescription>
      
      {success && (
        <SuccessMessage>
          Your information has been submitted successfully! Redirecting...
        </SuccessMessage>
      )}
      
      {error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}
      
      <FormContainer>
        <FormCard>
          <CardHeader>
            <CardTitle style={{ color: "#fff" }}>Contact Information</CardTitle>
            <HeaderInstructions>Please complete all required fields</HeaderInstructions>
          </CardHeader>
          
          <CardBody>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
              }}
              validationSchema={quickApplySchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched, values, setFieldValue }) => (
                <Form>
                  <FormGroup>
                    <Label htmlFor="firstName">
                      First Name<RequiredAsterisk>*</RequiredAsterisk>
                    </Label>
                    <Input type="text" id="firstName" name="firstName" />
                    <ErrorText>{errors.firstName && touched.firstName && errors.firstName}</ErrorText>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label htmlFor="lastName">
                      Last Name<RequiredAsterisk>*</RequiredAsterisk>
                    </Label>
                    <Input type="text" id="lastName" name="lastName" />
                    <ErrorText>{errors.lastName && touched.lastName && errors.lastName}</ErrorText>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label htmlFor="email">
                      Email<RequiredAsterisk>*</RequiredAsterisk>
                    </Label>
                    <Input type="email" id="email" name="email" />
                    <ErrorText>{errors.email && touched.email && errors.email}</ErrorText>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label htmlFor="phone">
                      Phone Number<RequiredAsterisk>*</RequiredAsterisk>
                    </Label>
                    <Input 
                      type="tel" 
                      id="phone" 
                      name="phone"
                      onChange={(e) => {
                        const formattedPhone = formatPhoneNumber(e.target.value);
                        setFieldValue("phone", formattedPhone);
                      }}
                      value={values.phone}
                    />
                    <ErrorText>{errors.phone && touched.phone && errors.phone}</ErrorText>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label htmlFor="resume">
                      Resume (PDF or Word, Max 5MB)<OptionalText>(Optional)</OptionalText>
                    </Label>
                    <FileDropArea className={resume ? 'has-file' : ''}>
                      {resume ? (
                        <FileName>{resume.name}</FileName>
                      ) : (
                        <FileMsg>Click to browse or drag and drop file</FileMsg>
                      )}
                      <FileInput 
                        type="file" 
                        id="resume" 
                        name="resume" 
                        accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={handleResumeChange}
                      />
                    </FileDropArea>
                  </FormGroup>
                  
                  <CardFooter>
                    <SubmitButton type="submit" disabled={submitting || isSubmitting || success}>
                      {submitting || isSubmitting ? 'Submitting...' : 'Submit Information'}
                    </SubmitButton>
                    
                    {/* Checkbox for consent/updates */}
                    <CheckboxContainer>
                      <label htmlFor="receiveUpdates">
                        Would you like to receive updates from us via email?
                      </label>
                      <input
                        type="checkbox"
                        id="receiveUpdates"
                        checked={receiveUpdates}
                        onChange={handleReceiveUpdatesChange}
                        style={{ marginLeft: '1rem' }}
                      />
                    </CheckboxContainer>
                    
                    {/* Legal disclaimers */}
                    <SmallGreyText>
                      By clicking "Submit Information," I agree to receive emails, text messages, and phone calls on behalf of Capstone Health and our affiliates. I understand these messages will be about employment opportunities. I acknowledge and agree to the Terms and Conditions and Privacy Policy.
                    </SmallGreyText>
                  </CardFooter>
                </Form>
              )}
            </Formik>
          </CardBody>
        </FormCard>
        
        <HelpText>
          Need help? Contact <a href="mailto:contact@capstone.net">contact@capstone.net</a>
        </HelpText>
      </FormContainer>
    </PageContainer>
  );
};

export default QuickApply;