// Updated ApplicationForm.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import JobApplicationForm from '../components/JobApplicationForm';
import salesforceApi from '../services/salesforceApi';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #28346c;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

// Same LoadingState style as in JobListings
const LoadingState = styled.div`
  text-align: center;
  padding: 3rem;
  font-size: 1.1rem;
  color: #7f8c8d;
`;

// Loading animation components (same as JobListings)
const LoadingDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

const LoadingDot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: #f47424;
  border-radius: 50%;
  animation: bounce-pulse 1.4s infinite ease-in-out;
  animation-delay: ${props => props.delay || '0s'};

  @keyframes bounce-pulse {
    0%, 80%, 100% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const ErrorState = styled.div`
  text-align: center;
  padding: 2rem;
  color: #e74c3c;
  border: 1px solid #e74c3c;
  border-radius: 8px;
`;

const ApplicationForm = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const jobData = await salesforceApi.getJobDetails(jobId);
        setJob(jobData);
        setError('');
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError('Failed to load job details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  // Helper to build "Order Type + Job Title + Specialty"
  const buildCombinedTitle = (jobObj) => {
    if (!jobObj) return '';
    const orderType = jobObj.vms_order_type__c || jobObj.Order_Type || '';
    const title =
      jobObj.JobTitlen ||
      jobObj.VMS_Job_Title__c ||
      jobObj.AVTRRT__Job_Title__c ||
      jobObj.Job_Title__c ||
      '';
    const specialty = jobObj.Specialityn || jobObj.Specialty || '';
    // Join non-empty parts with spaces:
    return [orderType, title, specialty].filter(Boolean).join(' ');
  };

  // Get a shortened job title for the back link
  const getShortJobTitle = () => {
    if (!job) return 'job';
    
    // Use specialty and order type if available
    if (job.Specialityn && job.Order_Type) {
      return `${job.Order_Type} ${job.Specialityn}`;
    }
    
    // Fallback to job title if available
    if (job.JobTitlen) {
      return job.JobTitlen;
    }
    
    return 'job';
  };

  return (
    <div>
      <PageContainer>
        {/* Back link to the specific job */}
        <BackLink to={`/jobs/${jobId}`}>&larr; Back to {loading ? 'job' : getShortJobTitle()}</BackLink>
        
        {loading ? (
          <LoadingState>
            <LoadingDots>
              <LoadingDot delay="0s" />
              <LoadingDot delay="0.2s" />
              <LoadingDot delay="0.4s" />
            </LoadingDots>
          </LoadingState>
        ) : error ? (
          <ErrorState>{error}</ErrorState>
        ) : job ? (
          <>
            {/* Pass combined "orderType + title + specialty" to the form */}
            <JobApplicationForm 
              jobTitle={buildCombinedTitle(job)} 
            />
          </>
        ) : (
          <ErrorState>Job not found.</ErrorState>
        )}
      </PageContainer>
    </div>
  );
};

export default ApplicationForm;