// src/data/reviewsData.js

export const reviews = [
  {
    id: 1,
    name: "Erin Bollinger",
    rating: 5,
    relativeDate: "1 week ago",
    textReview: "I'm very happy with my decision to try out a different job position. Jess White and Kristie Day are great resources concerning the onboarding process and any general questions that arise. Thank you for your continued support.",
    imagePath: "/healthcare-review-erin-testimonial.png"
  },
  {
    id: 2,
    name: "Abraham Ikeji",
    rating: 5,
    relativeDate: "3 weeks ago",
    textReview: "Assistance and support during my job experience was excellent. Some others could have given up on me, but they did not even with other many employees and prospects they had. A large part of my appreciation goes to my recruiter (her patience was admirable) and the onboarding team for being understanding. Overall it was an experience I will always remember. Thanks Capstone Health~ Abraham",
    imagePath: "/healthcare-review-abraham-nurse.png"
  },
  {
    id: 3,
    name: "H. Nicole",
    rating: 5,
    relativeDate: "3 weeks ago",
    textReview: "Great company to work for & always willing to support",
    imagePath: "/healthcare-review-nicole-staffing.png"
  },
  {
    id: 4,
    name: "April Thompson",
    rating: 5,
    relativeDate: "2 weeks ago",
    textReview: "I am grateful to work with Talent Acquisition Partner Jess White with Capstone. Jess is reachable, supportive and enthusiastic about assisting me with job placement. Jess has professionally supported me during the application and interview process while I am seeking a travel social work job. Jess goes above and beyond! I truly feel valued!",
    imagePath: "/healthcare-review-april-testimonial.png"
  },
  {
    id: 5,
    name: "Forrest Leirer",
    rating: 5,
    relativeDate: "a month ago",
    textReview: "I have had a great experience with this company so far. The communication is fast and efficient and I haven't had any problems that were not fixed promptly.",
    imagePath: "/healthcare-review-forrest-testimonial.png"
  },
  {
    id: 6,
    name: "Roberta Mohler",
    rating: 5,
    relativeDate: "a month ago",
    textReview: "I love working with Capstone Health! I have the best recruiter and always there for me. I feel so blessed.",
    imagePath: "/healthcare-review-roberta-testimonial.png"
  },
  {
    id: 7,
    name: "Adriane",
    rating: 5,
    relativeDate: "2 weeks ago",
    textReview: "Mr. Jess White is professional, honest and consistent with his services. It is clear that he is seasoned within his position and appears to understand how the healthcare industry works. He appears confident and not afraid to communicate via text, email or phone (he has no hesitation picking up the phone to call me). Because he is flexible and dynamic, Jess is able to gather information and helped coordinate an interview. I will leave another review once my professional relationship with Jess is complete. Thank you!",
    imagePath: "/healthcare-review-adriane-testimonial.png"
  },
  {
    id: 8,
    name: "James Pinet",
    rating: 5,
    relativeDate: "2 months ago",
    textReview: "Great company with plenty of support",
    imagePath: "/healthcare-review-james-testimonial.png"
  },
  {
    id: 9,
    name: "Ada Clark",
    rating: 5,
    relativeDate: "53 months ago",
    textReview: "My representative has opened many great doors for me and my future.... I truly appreciate everything done to make it worth my while. Thank you so much.",
    imagePath: "/healthcare-review-ada-testimonial.png"
  },
  {
    id: 10,
    name: "Ray Collins",
    rating: 5,
    relativeDate: "10 months ago",
    textReview: "Very professional, fast response times through the whole process. I can't wait to continue to work with them.",
    imagePath: "/healthcare-review-ray-testimonial.png"
  },
  {
    id: 11,
    name: "Sarah Raner",
    rating: 5,
    relativeDate: "11 months ago",
    textReview: "Super friendly and easily available to you.",
    imagePath: "/healthcare-review-sarah-testimonial.png"
  },
  {
    id: 12,
    name: "Advertus Sloh",
    rating: 5,
    relativeDate: "a year ago",
    textReview: "Good work environment where leaders put employees interest above their personal interests.",
    imagePath: "/healthcare-review-advertus-testimonial.png"
  }
];

export default reviews;