// Updated src/services/salesforceApi.js

import axios from 'axios';

class SalesforceApiService {
  constructor() {
    // Base URL varies between development and production
    // In development, we'll use a relative URL to leverage the proxy
    this.baseUrl = process.env.NODE_ENV === 'production' 
      ? 'https://cerebralstaffing.my.salesforce-sites.com/services/apexrest'
      : '/services/apexrest'; // This will be proxied in development
    
    console.log(`Initializing SalesforceApiService with baseUrl: ${this.baseUrl}`);
    
    // Create axios instance for Salesforce API calls
    this.api = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Add response interceptor for debugging
    this.api.interceptors.response.use(
      response => {
        console.log('API Response:', response);
        return response;
      },
      error => {
        console.error('API Error:', error);
        return Promise.reject(error);
      }
    );
  }

  // Get all published jobs
  async getJobs() {
    try {
      // The cerebralstaffing endpoint returns the most complete job data
      const params = { Job_Type: 'Clinical' };
      console.log('Fetching jobs from cerebralstaffing with params:', params);
      
      const response = await this.api.get('/cerebralstaffing', { params });
      if (!response.data) {
        throw new Error('No data received from API');
      }
      return response.data || [];
    } catch (error) {
      console.error('Error fetching jobs:', error);
      throw new Error('Failed to fetch jobs. Check console for details.');
    }
  }

  // Get job details by ID
  async getJobDetails(jobId) {
    try {
      // Use Record_Id parameter to get a specific job
      const params = { 
        Record_Id: jobId
      };
      
      console.log('Fetching job details for:', jobId);
      const response = await this.api.get('/cerebralstaffing', { params });
      
      // The endpoint returns an array, but we need just the single job
      const jobs = response.data || [];
      if (jobs.length === 0) {
        throw new Error('Job not found');
      }
      
      return jobs[0];
    } catch (error) {
      console.error('Error fetching job details:', error);
      throw new Error('Failed to fetch job details. Job may not exist or cannot be accessed.');
    }
  }

  // Get portal source options
  async getPortalSources() {
    try {
      const response = await this.api.get('/cerebralstaffportal');
      return response.data || [];
    } catch (error) {
      console.error('Error fetching portal sources:', error);
      throw new Error('Failed to fetch portal sources');
    }
  }

  // Submit job application - UPDATED to handle applications with and without resume
  async submitJobApplication(applicationData, resumeFile) {
    try {
      // Determine if this is a resume or non-resume application
      const hasResume = !!resumeFile;
      
      let payload;
      
      if (hasResume) {
        // With resume flow
        const resumeBase64 = await this.convertFileToBase64(resumeFile);
        
        // Create contact values JSON structure
        // For Quick Apply, we don't set Search_parameters__c
        const contactValues = {
          "Candidate_Source__c": "Capstone Website",
          "AVTRRT__Source__c": "Add Resume"
        };
        
        // Convert to JSON string
        const contactValuesJson = JSON.stringify(contactValues);
        
        // Prepare the payload for resume applications
        payload = {
          applicationData: {
            firstName: applicationData.firstName,
            lastName: applicationData.lastName,
            resumeFileName: resumeFile.name,
            contactValues: contactValuesJson
          },
          resume: resumeBase64
        };
        
        // If a job ID is provided (regular application), include it
        if (applicationData.jobId) {
          payload.applicationData.jobId = applicationData.jobId;
        }
      } else {
        // Non-resume flow - direct contact creation
        payload = {
          applicationData: {
            firstName: applicationData.firstName,
            lastName: applicationData.lastName,
            email: applicationData.email,
            phone: applicationData.phone,
            hasResume: false
          }
        };
        
        // Include job ID only if it's provided
        if (applicationData.jobId) {
          payload.applicationData.jobId = applicationData.jobId;
        }
      }
      
      console.log('Submitting application payload:', { 
        ...payload,
        resume: hasResume ? '[base64 data]' : undefined
      });
      
      // Use the jobportal API endpoint
      const response = await this.api.post('/jobportal/v1/apply', payload);
      return { 
        success: true, 
        message: response.data.message || (hasResume ? 'Resume uploaded successfully' : 'Application submitted successfully') 
      };
    } catch (error) {
      console.error('Error submitting application:', error);
      throw new Error(error.response?.data?.error || 'Failed to submit application. Please try again later.');
    }
  }

  // Helper method to get file extension
  getFileExtension(filename) {
    const ext = filename.split('.').pop().toLowerCase();
    if (ext === 'pdf') return 'pdf';
    if (ext === 'doc') return 'doc';
    if (ext === 'docx') return 'docx';
    return 'pdf'; // Default to PDF if unknown
  }
  
  // Helper method to convert a file to base64
  async convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Get the base64 string from the FileReader result
        // Remove the data URL prefix (e.g., "data:application/pdf;base64,")
        const base64 = reader.result.split(',')[1];
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}

// Create instance
const salesforceApiService = new SalesforceApiService();

// Export instance
export default salesforceApiService;