import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { reviews as desktopReviews } from '../data/reviewsData';
import { reviews as mobileReviews } from '../data/reviewsDataMobile';

// Constant for desktop cards per view
const DESKTOP_CARDS_PER_VIEW = 3;

// Styled components
const ReviewsSection = styled.section`
  padding: 0 1.5rem 3rem 1.5rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin: 0;
`;

const ReviewsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const ReviewsActionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .5rem;
`;

const ReviewsActionSvg = styled.svg`
  margin-bottom: 0.5rem;
  max-width: 100%
`;

const WriteReviewButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 2.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.25);
  margin-top: 0.25rem;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: all 0.6s ease;
  }
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: #28346c;
    transform: scale(1.25) translateY(-2px);
    box-shadow: 0 6px 15px rgba(40,52,108,0.25);
    text-decoration: none;
    color: white;
  }
  
  &:hover::before {
    left: 100%;
  }
  
  &:active {
    transform: scale(1.25) translateY(-1px);
  }
`;

// Carousel components
const CarouselContainer = styled.div`
  position: relative;
  padding: 0 2.7rem;
  margin: 0 auto;
  max-width: ${props => props.isMobile ? '600px' : '1100px'};
`;

const CarouselViewport = styled.div`
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  width: 100%;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  width: ${props => `${props.slidesCount * 100}%`};
`;

const CarouselSlide = styled.div`
  flex: 0 0 ${props => `${100 / props.slidesCount}%`};
  display: flex;
  padding: 0 5px;
`;

const ReviewCard = styled.div`
  background-color: #f2f2f2;
  padding: 1.25rem;
  flex: 0 0 ${props => props.isMobile ? '100%' : `${100 / DESKTOP_CARDS_PER_VIEW}%`};
  margin: 0 5px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  }
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const ReviewerImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 0.75rem;
  object-fit: cover;
  border: 2px solid #f1f3f5;
`;

const ReviewerInfo = styled.div`
  flex: 1;
`;

const ReviewerName = styled.h4`
  color: #2c3e50;
  margin: 0 0 0.15rem 0;
  font-size: 0.95rem;
  font-weight: 600;
`;

const ReviewMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
`;

const RatingStars = styled.div`
  display: flex;
  margin-right: 1rem;
  margin-left: .4rem;
  color: #f1c40f;
  transform: scale(1.3);
`;

const Star = styled.span`
  color: #f1c40f;
`;

const ReviewDate = styled.span`
  color: #7f8c8d;
`;

const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ReviewText = styled.p`
  color: #2c3e50;
  line-height: 1.5;
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
  position: relative;
  padding: 0 0.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 1.5rem;
`;

const GoogleBadge = styled.a`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  color: #7f8c8d;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: #4285F4;
    text-decoration: underline;
  }
`;

const GoogleSmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 0.4rem;
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1.1rem;
  color: #28346c;
  z-index: 2;
  transition: all 0.2s;
  
  &:hover {
    background-color: #28346c;
    color: white;
    transform: translateY(-50%) scale(1.05);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &.prev {
    left: 0;
  }
  
  &.next {
    right: 0;
  }
`;

const NavigationDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const NavigationDot = styled.button`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#28346c' : '#ddd'};
  margin: 0 5px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    background-color: ${props => props.active ? '#28346c' : '#aaa'};
    transform: scale(1.2);
  }
`;

// Format review text
const formatReviewText = (text) => {
  // Return the text as is or empty string if no text
  return text || '';
};

// Custom hook to detect mobile devices
const useMobileDetection = () => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    // Define the media query
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    
    // Handler function to update state
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    
    // Set initial value
    setIsMobile(mediaQuery.matches);
    
    // Add event listener for changes
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    
    // Clean up
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  
  return isMobile;
};

/**
 * Chunks an array into smaller arrays of a specified size
 */
const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

const GoogleReviews = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const carouselRef = useRef(null);
  const autoScrollIntervalRef = useRef(null);
  
  // Detect if user is on mobile
  const isMobile = useMobileDetection();
  
  // Select the appropriate reviews data based on device type
  const reviewsData = isMobile ? mobileReviews : desktopReviews;
  
  // Process reviews with formatted text
  const formattedReviews = reviewsData.map(review => ({
    ...review,
    formattedTextReview: formatReviewText(review.textReview)
  }));
  
  // Group reviews by the number of cards per view
  const cardsPerView = isMobile ? 1 : DESKTOP_CARDS_PER_VIEW;
  const groupedReviews = chunkArray(formattedReviews, cardsPerView);
  
  // Calculate total number of slides
  const totalSlides = groupedReviews.length;
  
  // Memoize the goToNextSlide function to avoid dependency issues
  const memoizedGoToNextSlide = useCallback(() => {
    if (isAnimating) return;
    
    setIsAnimating(true);
    setActiveSlide(prev => {
      return (prev + 1) % totalSlides;
    });
    
    setTimeout(() => {
      setIsAnimating(false);
    }, 600); // Match transition duration
  }, [isAnimating, totalSlides]);
  
  // Auto-scroll setup
  useEffect(() => {
    const startAutoScroll = () => {
      autoScrollIntervalRef.current = setInterval(() => {
        if (!isPaused) {
          memoizedGoToNextSlide();
        }
      }, 5000); // Change slide every 5 seconds
    };
    
    startAutoScroll();
    
    return () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }
    };
  }, [isPaused, memoizedGoToNextSlide]);
  
  // Reset active slide when switching between mobile and desktop data
  useEffect(() => {
    setActiveSlide(0);
  }, [isMobile]);
  
  const goToPrevSlide = () => {
    if (isAnimating) return;
    
    setIsAnimating(true);
    setActiveSlide(prev => {
      return prev === 0 ? totalSlides - 1 : prev - 1;
    });
    
    setTimeout(() => {
      setIsAnimating(false);
    }, 600); // Match transition duration
  };
  
  const goToNextSlide = memoizedGoToNextSlide;
  
  const goToSlide = (index) => {
    if (isAnimating) return;
    
    setIsAnimating(true);
    setActiveSlide(index);
    
    setTimeout(() => {
      setIsAnimating(false);
    }, 600); // Match transition duration
  };
  
  const handleMouseEnter = () => {
    setIsPaused(true);
  };
  
  const handleMouseLeave = () => {
    setIsPaused(false);
  };
  
  // Render single review card
  const renderReviewCard = (review, isMobileView) => (
    <ReviewCard key={review.id} isMobile={isMobileView}>
      <ReviewHeader>
        <ReviewerImage
          src={review.imagePath}
          alt={`${review.name} profile`}
        />
        <ReviewerInfo>
          <ReviewerName>{review.name}</ReviewerName>
          <ReviewMeta>
            <RatingStars>
              {Array.from({ length: 5 }).map((_, i) => (
                <Star key={i}>
                  {i < review.rating ? '★' : '☆'}
                </Star>
              ))}
            </RatingStars>
            <ReviewDate>{review.relativeDate}</ReviewDate>
          </ReviewMeta>
        </ReviewerInfo>
      </ReviewHeader>
      
      <ReviewContent>
        <ReviewText>
          {review.formattedTextReview}
        </ReviewText>
        
        <GoogleBadge 
          href="https://g.co/kgs/Gfyxrc4" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <GoogleSmallIcon src="/google-review-icon.png" alt="Google Reviews" />
          Posted on Google
        </GoogleBadge>
      </ReviewContent>
    </ReviewCard>
  );
  
  return (
    <ReviewsSection>
      <ReviewsContainer>
        <CarouselContainer isMobile={isMobile}>
          <CarouselButton
            className="prev"
            onClick={goToPrevSlide}
            aria-label="Previous review"
            disabled={isAnimating}
          >
            &#10094;
          </CarouselButton>
          
          <CarouselViewport
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={carouselRef}
          >
            <CarouselTrack
              style={{
                transform: `translateX(-${activeSlide * (100 / totalSlides)}%)`
              }}
              slidesCount={totalSlides}
            >
              {groupedReviews.map((reviewGroup, slideIndex) => (
                <CarouselSlide key={`slide-${slideIndex}`} slidesCount={totalSlides}>
                  {reviewGroup.map(review => renderReviewCard(review, isMobile))}
                  
                  {/* Add empty placeholder cards if needed to fill the last slide */}
                  {slideIndex === totalSlides - 1 && 
                   reviewGroup.length < cardsPerView && 
                   Array.from({ length: cardsPerView - reviewGroup.length }).map((_, i) => (
                     <ReviewCard 
                       key={`empty-${i}`} 
                       isMobile={isMobile} 
                       style={{ visibility: 'hidden' }}
                     />
                   ))}
                </CarouselSlide>
              ))}
            </CarouselTrack>
          </CarouselViewport>
          
          <CarouselButton
            className="next"
            onClick={goToNextSlide}
            aria-label="Next review"
            disabled={isAnimating}
          >
            &#10095;
          </CarouselButton>
        </CarouselContainer>
        
        <NavigationDots>
          {Array.from({ length: totalSlides }).map((_, index) => (
            <NavigationDot
              key={index}
              active={activeSlide === index}
              onClick={() => goToSlide(index)}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </NavigationDots>
        
        <ReviewsActionSection>
          <ReviewsActionSvg width="600" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 100">
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"
                  style={{ fontFamily: "'Brush Script MT', cursive", fontSize: "48px", fill: "#28346c" }}>
              Have something to say?
            </text>
          </ReviewsActionSvg>
          <WriteReviewButton 
            href="https://search.google.com/local/writereview?placeid=ChIJT8NNXVuZ7ocRfnxlILMNjl8" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f47424" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
            </svg>
            Write A Review
          </WriteReviewButton>
        </ReviewsActionSection>
      </ReviewsContainer>
    </ReviewsSection>
  );
};

export default GoogleReviews;