// src/components/Navigation.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

// Fade In Animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Slide Down Animation
const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Slide In From Right (Mobile Nav)
const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Custom useClickAway hook
const useClickAway = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if the ref is not set or if the click was inside the referenced element
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

// Navigation Data
const navigationData = {
  mainItems: [
    {
      title: 'Browse Jobs',
      path: '/jobs?collapsed=true',
      highlight: true,
      dropdown: [
        { name: 'View All Jobs', path: '/jobs?sort=recent&collapsed=true' },
        { name: 'Nursing Jobs', path: '/jobs?title=RN&sort=recent&collapsed=true' },
        { name: 'Long Term Care', path: '/jobs?title=RN,LPN%2FLVN,CNA&sort=recent&collapsed=true' },
        { name: 'Cath Lab Jobs', path: '/jobs?specialty=Cardiac%20Cath%20Lab&sort=recent&collapsed=true' },
        {
          name: 'Laboratory Jobs',
          path: '/jobs?specialty=Cardiac%20Cath%20Lab,EP%20Lab,Laboratory,Medical%20Laboratory%20Tech%20(MLT)&sort=recent&collapsed=true',
        },
        { name: 'Radiology Jobs', path: '/jobs?title=Rad%20Tech&sort=recent&collapsed=true' },
        { name: 'Therapy Jobs',
          path: '/jobs?title=PT%2CPTA%2COT%2CSLP%2CSocial+Worker&sort=recent&collapsed=true'},
        { name: 'Pharmacy Jobs', path: '/jobs?specialty=Pharmacy&sort=recent&collapsed=true' },
      ],
    },
    {
      title: 'About',
      path: '/about',
      dropdown: [
        { name: 'Why Capstone', path: '/why-capstone' },
        { name: 'Our Mission', path: '/about' },
      ],
    },
    {
      title: 'Contact',
      path: '/contact',
    },
  ],
};

// Styled Components
const NavContainer = styled.header`
  position: ${(props) => (props.isSticky ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: translateY(${(props) => (!props.isVisible && props.isSticky ? '-100%' : '0')});
  
  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    `}
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 3;
  transition: opacity 0.2s ease;
  
  &:hover {
    opacity: 0.9;
  }
  
  &:focus {
    outline: 2px solid #28346c;
    outline-offset: 4px;
  }
`;

const LogoImage = styled.img`
  height: auto;
  max-height: 65px;
  width: auto;
  
  @media (max-width: 768px) {
    max-height: 50px;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  
  
  @media (max-width: 1023px) {
    display: none;
  }
`;

const NavItem = styled.li`
  position: relative;
  margin: 0 0.25rem;
`;

const NavLink = styled(Link)`
  display: block;
  color: #28346c;
  font-weight: 500;
  font-size: 1.05rem;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;
  border-radius: 4px;
  
  &:hover {
    background-color: rgba(40, 52, 108, 0.05);
    color: #3a4785;
  }
  
  ${(props) =>
    props.active &&
    css`
      font-weight: 600;
    `}
  
  ${(props) =>
    props.highlight &&
    css`
      color: #28346c;
      font-weight: 600;
    `}
  
  @media (max-width: 1023px) {
    font-size: 1.25rem;
    padding: 1rem 0.5rem;
    width: 100%;
  }
`;

const NavLinkWithIcon = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  text-align: left;
  width: 100%;
  
  ${(props) =>
    props.active &&
    css`
      background-color: rgba(40, 52, 108, 0.05);
    `}
`;

const QuickApplyButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5935f, #f47424, #e05e0c);
  color: #28346c;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 0.5rem;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #f7a77f, #f47424, #d25a0c);
    text-decoration: none;
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 1023px) {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.75rem 1rem;
    justify-content: flex-start;
  }
`;

const DropdownArrow = styled.span`
  margin-left: 6px;
  transition: transform 0.2s ease;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  height: 0.45em;
  width: 0.45em;
  position: relative;
  transform: ${(props) => (props.isOpen ? 'rotate(135deg)' : 'rotate(45deg)')};
  vertical-align: middle;
  transform-origin: 50% 50%;
  top: ${(props) => (props.isOpen ? '0.15em' : '-0.15em')};
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 220px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  animation: ${fadeIn} 0.2s ease-out;
  z-index: 10;
  overflow: hidden;
`;

const DropdownItem = styled(Link)`
  display: block;
  color: #28346c;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  white-space: nowrap;
  
  &:hover {
    background-color: rgba(40, 52, 108, 0.05);
  }
`;

const DropdownSubHeader = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  color: #28346c;
  padding: 0.75rem 1.25rem 0.5rem 1.25rem;
  margin-top: 0.25rem;
  border-top: 1px solid #f0f0f0;
`;

const MobileMenuButton = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 3;
  
  span {
    width: 100%;
    height: 2px;
    background-color: #28346c;
    transition: all 0.3s ease;
    transform-origin: 1px;
    border-radius: 2px;
  }
  
  @media (max-width: 1023px) {
    display: flex;
  }
`;

const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  animation: ${fadeIn} 0.3s ease;
`;

const MobileNavOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100vh;
  background-color: white;
  z-index: 999;
  overflow-y: auto;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  animation: ${slideInRight} 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const CloseOverlayButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.75rem;
  cursor: pointer;
  color: #28346c;
  z-index: 1001; 
`;

const MobileNavLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem;
`;

const MobileNavItem = styled.li`
  margin-bottom: 0.5rem;
`;

const MobileDropdownContent = styled.div`
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-left: 2px solid rgba(40, 52, 108, 0.2);
  animation: ${slideDown} 0.2s ease-out;
`;

const MobileDropdownItem = styled(Link)`
  display: block;
  color: #28346c;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-size: 1rem;
  
  &:hover {
    background-color: rgba(40, 52, 108, 0.05);
  }
`;

const MobileDivider = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  margin: 0.5rem 1rem;
  width: calc(100% - 2rem);
`;

const MobileDropdownSubHeader = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #28346c;
  padding: 0.75rem 1rem 0.25rem 1rem;
  margin-top: 0.25rem;
  border-top: 1px solid #f0f0f0;
`;

const JobsHighlight = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #28346c;
  
  &::before {
    content: '•'; 
    color: #28346c;
    margin-right: 0.5rem;
    font-size: 1.5rem;
    line-height: 1;
  }
`;

const Navigation = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileActiveDropdown, setMobileActiveDropdown] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);

  const navRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const location = useLocation();

  // Close dropdown when clicking outside
  useClickAway(navRef, () => {
    setActiveDropdown(null);
  });

  // Close mobile menu when clicking/tapping outside
  useClickAway(mobileMenuRef, () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      document.body.style.overflow = 'unset';
    }
  });

  // Handle page changes - close mobile menu and reset dropdowns
  useEffect(() => {
    setMobileMenuOpen(false);
    setActiveDropdown(null);
    setMobileActiveDropdown(null);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Handle keyboard events (Escape key)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && mobileMenuOpen) {
        setMobileMenuOpen(false);
        document.body.style.overflow = 'unset';
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mobileMenuOpen]);

  // Handle swipe right to dismiss mobile menu
  useEffect(() => {
    if (!mobileMenuOpen || !mobileMenuRef.current) return;
    
    const mobileMenuElement = mobileMenuRef.current;
    let touchStartX = 0;
    let touchEndX = 0;
    
    const handleTouchStart = (e) => {
      touchStartX = e.touches[0].clientX;
    };
    
    const handleTouchMove = (e) => {
      touchEndX = e.touches[0].clientX;
    };
    
    const handleTouchEnd = () => {
      // If swiped right (touchEndX > touchStartX) and the difference is significant
      if (touchEndX - touchStartX > 50) {
        setMobileMenuOpen(false);
        document.body.style.overflow = 'unset';
      }
    };
    
    mobileMenuElement.addEventListener('touchstart', handleTouchStart);
    mobileMenuElement.addEventListener('touchmove', handleTouchMove);
    mobileMenuElement.addEventListener('touchend', handleTouchEnd);
    
    return () => {
      mobileMenuElement.removeEventListener('touchstart', handleTouchStart);
      mobileMenuElement.removeEventListener('touchmove', handleTouchMove);
      mobileMenuElement.removeEventListener('touchend', handleTouchEnd);
    };
  }, [mobileMenuOpen]);

  // Smart scroll behavior
  useEffect(() => {
    const threshold = 50; // Minimum scroll before navbar hides
    let timeoutId = null;
    
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > threshold) {
        setHasScrolled(true);
        
        // Determine scroll direction and update visibility
        if (currentPosition < scrollPosition) {
          // Scrolling up - show the navigation
          setIsVisible(true);
        } else if (currentPosition > scrollPosition) {
          // Scrolling down - hide the navigation
          setIsVisible(false);
        }
      } else {
        // At the very top
        setHasScrolled(false);
        setIsVisible(true);
      }
      
      setScrollPosition(currentPosition);
	  // Clear previous timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      
      // Set a new timeout to show nav after 3 seconds of no scrolling
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 3000);
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [scrollPosition]);

  const toggleMobileMenu = () => {
    const willOpen = !mobileMenuOpen;
    setMobileMenuOpen(willOpen);
    
    // When closing mobile menu, reset active dropdowns
    if (!willOpen) {
      setMobileActiveDropdown(null);
    } else {
      // When opening mobile menu, expand Browse Jobs by default (index 0)
      setMobileActiveDropdown(0);
    }
    
    // Prevent body scroll when menu is open
    if (willOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  // Used for both click and hover interactions on desktop
  const toggleDropdown = (index, forceState = null) => {
    if (forceState !== null) {
      setActiveDropdown(forceState ? index : null);
    } else {
      setActiveDropdown(activeDropdown === index ? null : index);
    }
  };
  
  const toggleMobileDropdown = (index) => {
    setMobileActiveDropdown(mobileActiveDropdown === index ? null : index);
  };

  return (
    <NavContainer
      ref={navRef}
      isVisible={isVisible}
      isSticky={hasScrolled}
      data-testid="main-navigation"
    >
      <NavWrapper>
        {/* Logo / Brand */}
        <LogoContainer to="/" aria-label="Capstone Health Home">
          <LogoImage
            src="/capstone-healthcare-staffing-logo.png"
            alt="Capstone Health"
            width="337"
            height="65"
          />
        </LogoContainer>
        
        {/* Desktop Navigation with hover behavior */}
        <NavLinks>
          {/* Main navigation items (Browse Jobs, About, and Contact) */}
          {navigationData.mainItems.map((item, index) => (
            <NavItem 
              key={`desktop-nav-${index}`}
              onMouseEnter={() => {
                const isMobile = window.innerWidth <= 1023;
                if (!isMobile && item.dropdown) {
                  toggleDropdown(index, true); // Use true to force dropdown open
                }
              }}
              onMouseLeave={() => {
                const isMobile = window.innerWidth <= 1023;
                if (!isMobile) {
                  toggleDropdown(index, false); // Use false to force dropdown closed
                }
              }}
            >
              {item.dropdown ? (
                <>
                  {/* For Browse Jobs (and other dropdown items with path), make the title a link */}
                  <NavLink
                    to={item.path || '#'}
                    highlight={item.highlight}
                    active={location.pathname === item.path}
                  >
                    {item.title}
                    <DropdownArrow isOpen={activeDropdown === index} />
                  </NavLink>
                  
                  {activeDropdown === index && (
                    <DropdownContent>
                      {item.dropdown.map((dropdownItem, dIndex) => {
                        // Skip "View All Jobs" on desktop for the Browse Jobs dropdown since the header already links to it
                        if (index === 0 && dIndex === 0 && dropdownItem.name === 'View All Jobs') {
                          return null;
                        }
                        
                        if (dropdownItem.isHeader) {
                          return (
                            <DropdownSubHeader key={`subheader-${dIndex}`}>
                              {dropdownItem.name}
                            </DropdownSubHeader>
                          );
                        } else {
                          return (
                            <DropdownItem 
                              key={`dropdown-${index}-${dIndex}`}
                              to={dropdownItem.path}
                            >
                              {dropdownItem.name}
                            </DropdownItem>
                          );
                        }
                      })}
                    </DropdownContent>
                  )}
                </>
              ) : (
                <NavLink
                  to={item.path}
                  highlight={item.highlight}
                  active={location.pathname === item.path}
                >
                  {item.title}
                </NavLink>
              )}
            </NavItem>
          ))}
          
          {/* Quick Apply Button - to the right of Contact */}
          <NavItem>
            <QuickApplyButton to="/quickapply">
              Quick Apply
            </QuickApplyButton>
          </NavItem>
        </NavLinks>
        
        {/* Mobile Menu Button */}
        <MobileMenuButton
          onClick={toggleMobileMenu}
          aria-label={mobileMenuOpen ? 'Close menu' : 'Open menu'}
          aria-expanded={mobileMenuOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </MobileMenuButton>
      </NavWrapper>
      
      {/* Mobile Navigation Overlay */}
      {mobileMenuOpen && (
        <>
          {/* Semi-transparent backdrop */}
          <MobileBackdrop />
          
          <MobileNavOverlay ref={mobileMenuRef}>
            {/* Close (X) button in top-right corner */}
            <CloseOverlayButton onClick={toggleMobileMenu} aria-label="Close mobile menu">
              &times;
            </CloseOverlayButton>

            <MobileNavLinks>
              {/* All navigation items (Browse Jobs, About, and Contact) */}
              {navigationData.mainItems.map((item, index) => (
                <MobileNavItem key={`mobile-nav-${index}`}>
                  {item.dropdown ? (
                    <>
                      <NavLinkWithIcon
                        onClick={() => toggleMobileDropdown(index)}
                        active={mobileActiveDropdown === index}
                        highlight={item.highlight}
                        as="button"
                        type="button"
                        aria-expanded={mobileActiveDropdown === index}
                        aria-haspopup="true"
                      >
                        {item.title}
                        <DropdownArrow isOpen={mobileActiveDropdown === index} />
                      </NavLinkWithIcon>
                      
                      {mobileActiveDropdown === index && (
                        <MobileDropdownContent>
                          {item.dropdown.map((dropdownItem, dIndex) => {
                            // Add a divider after "View All Jobs" in the Browse Jobs dropdown
                            if (index === 0 && dIndex === 0 && dropdownItem.name === "View All Jobs") {
                              return (
                                <React.Fragment key={`mobile-dropdown-${index}-${dIndex}`}>
                                  <MobileDropdownItem
                                    to={dropdownItem.path}
                                    onClick={toggleMobileMenu}
                                  >
                                    {dropdownItem.name}
                                  </MobileDropdownItem>
                                  <MobileDivider />
                                </React.Fragment>
                              );
                            }
                            else if (dropdownItem.isHeader) {
                              return (
                                <MobileDropdownSubHeader key={`mob-subheader-${dIndex}`}>
                                  {dropdownItem.name}
                                </MobileDropdownSubHeader>
                              );
                            } else {
                              return (
                                <MobileDropdownItem
                                  key={`mobile-dropdown-${index}-${dIndex}`}
                                  to={dropdownItem.path}
                                  onClick={toggleMobileMenu}
                                >
                                  {dropdownItem.name}
                                </MobileDropdownItem>
                              );
                            }
                          })}
                        </MobileDropdownContent>
                      )}
                    </>
                  ) : (
                    <NavLink
                      to={item.path}
                      as={Link}
                      highlight={item.highlight}
                      onClick={toggleMobileMenu}
                      active={location.pathname === item.path}
                    >
                      {item.highlight ? (
                        <JobsHighlight>{item.title}</JobsHighlight>
                      ) : (
                        item.title
                      )}
                    </NavLink>
                  )}
                </MobileNavItem>
              ))}
              
              {/* Quick Apply Button in Mobile - after Contact */}
              <MobileNavItem>
                <QuickApplyButton to="/quickapply" onClick={toggleMobileMenu}>
                  Quick Apply
                </QuickApplyButton>
              </MobileNavItem>
            </MobileNavLinks>
          </MobileNavOverlay>
        </>
      )}
    </NavContainer>
  );
};

export default Navigation;