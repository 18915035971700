// src/pages/Home.jsx

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUserFriends, FaTrophy, FaHandshake } from 'react-icons/fa';
import GoogleReviews from '../components/GoogleReviews';
import EnhancedHeroSection from '../components/EnhancedHeroSection';
import salesforceApi from '../services/salesforceApi';


const Section = styled.section`
  padding: 4rem 2rem;
  background-color: ${props => props.bgColor || '#f8f9fa'};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem;
  background-color: #DCDCDC;
  border-radius: 1rem;
  box-shadow: 0 3px 10px rgba(40, 52, 108, 0.2);
  width: 50%;
  box-sizing: border-box;
  color: #696969;
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 1.25rem 1.5rem;
    margin-bottom: 2rem;
    border-radius: 0.75rem;
  }
`;

const SectionTitle2 = styled(Link)`
  display: block;
  text-align: center;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem;
  background-color: #28346c;
  border-radius: 1rem;
    box-shadow: 
    8px 8px 20px rgba(244, 116, 36, 0.25),
    0 3px 10px rgba(0, 0, 0, 0.2); 
  width: 50%;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(40, 52, 108, 0.3);
    text-decoration: none;
    color: white;
  }
  
  /* Wrap content with stylized quotes using Bodoni */
  &::before {
    content: '"';
    color: #f47424;
    margin-right: 0.2rem;
    font-family: 'Bodoni Moda', serif;
  }
  
  &::after {
    content: '"';
    color: #f47424;
    margin-left: 0.2rem;
    font-family: 'Bodoni Moda', serif;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 1.25rem 1.5rem;
    margin-bottom: 2rem;
    border-radius: 0.75rem;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f47424;
  text-align: center;
  
  svg {
    font-size: 2.5rem;
  }
`;

const FeatureTitle = styled.h3`
  margin-bottom: 1rem;
  color: #2c3e50;
  text-align: center;
`;

const FeatureDescription = styled.p`
  color: #7f8c8d;
  line-height: 1.6;
`;

const TaglineCard = styled(FeatureCard)`
  grid-column: 1 / -1;
  text-align: center;
  background-color: #28346c;
  color: white;
    box-shadow: 
    8px 8px 20px rgba(244, 116, 36, 0.25), /* Subtle orange flare bottom-right */
    0 3px 10px rgba(0, 0, 0, 0.2);         /* Soft original shadow */
`;

// Tagline styling with mobile-specific layout
const Tagline = styled.h3`
  color: white;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  position: relative;

  /* Desktop styling for the SVGs */
  &::before,
  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 120px;
    height: 20px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgMjQwIDQwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wLDIwIEw1MCwyMCBMMzAsMjAgTDUwLDIwIEw2MCw1IEw3MCwzNSBMODAsMjAgTDE2MCwyMCBMMTcwLDUgTDE4MCwzNSBMMTkwLDIwIEwyNDAsMjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzY5Njk2OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=")
      no-repeat center / contain;
  }

  &::before {
    margin-right: 0.5rem;
  }

  &::after {
    margin-left: 0.5rem;
    transform: scaleX(-1);
  }

  /* Hide default display on mobile */
  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 0.25rem;
    
    &::before,
    &::after {
      display: none;
    }
  }
`;

/* Desktop version of the tagline */
const DesktopTagline = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

/* Mobile version of the tagline */
const MobileTagline = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const MobileSvg = styled.div`
  width: 120px;
  height: 20px;
  margin: 8px 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgMjQwIDQwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wLDIwIEw1MCwyMCBMMzAsMjAgTDUwLDIwIEw2MCw1IEw3MCwzNSBMODAsMjAgTDE2MCwyMCBMMTcwLDUgTDE4MCwzNSBMMTkwLDIwIEwyNDAsMjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzY5Njk2OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=")
    no-repeat center / contain;
  
  &.right {
    transform: scaleX(-1);
  }
`;

const MobileTaglineWord = styled.div`
  font-size: 2rem;
  line-height: 1.2;
  margin: 4px 0;
`;

const TrademarkSymbol = styled.sup`
  color: #f47424; /* Orange color for the TM symbol */
  font-size: 1rem;
`;

// TaglineSubtext component for mobile
const TaglineSubtext = styled.p`
  color: #e0e0e0;
  font-size: 1.2rem;
  
  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: ${props => props.active ? '#28346c' : '#f8f9fa'};
  color: ${props => props.active ? 'white' : '#2c3e50'};
  border: 2px solid #28346c;
  cursor: pointer;
  transition: all 0.3s;
  
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  
  &:hover {
    background-color: ${props => props.active ? '#28346c' : '#e6e9ec'};
  }
`;

const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const CategoryCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CategoryTitle = styled.h3`
  color: black;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: center;
`;

const JobsList = styled.ul`
  list-style: disc;
  padding-left: 1.5rem;
  margin: 0;
`;

const JobItem = styled.li`
  margin-bottom: 0.5rem;
  color: #54698d;
`;

const ReadMoreLink = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  color: #28346c;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
  
  &:hover {
    color: #3498db;
    text-decoration: underline;
  }
`;

// Background Image Styled Components //

const BackgroundImageSection = styled.section`
  padding: 4rem 2rem;
  position: relative;
  background-color: white; /* Changed to white background */
  color: #333; /* Changed text color for better visibility on white */
  min-height: 800px; /* Set a minimum height for consistency */
  
  &::before {
    content: '';
    position: absolute;
    top: 185px;
    left: 0px;
    width: 100%;
    height: 700px; /* Fixed height for the background image */
    background-image: url('/healthcare-careers-capstone-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.2; /* Adjust opacity to make sure content is visible */
    z-index: 1;
    pointer-events: none; /* Ensures the image doesn't interfere with interactions */
  }
`;

// Container for the background section
const BackgroundContainer = styled(Container)`
  position: relative;
  z-index: 2; /* Ensure the content appears on top of the background image */
`;

const BackgroundSectionTitle = styled(SectionTitle)`
  background-color: rgba(220, 220, 220, 0.8);
  color: #28346c;
  box-shadow: 
    8px 8px 20px rgba(244, 116, 36, 0.25), /* Subtle orange flare bottom-right */
    0 3px 10px rgba(0, 0, 0, 0.2);         /* Soft original shadow */
`;

// Tab styling
const BackgroundTab = styled(Tab)`
  background-color: ${props => props.active ? '#28346c' : 'rgba(248, 249, 250, 0.9)'};
  color: ${props => props.active ? 'white' : '#2c3e50'};
  border: 2px solid #28346c;
  font-weight: bold;
  
  &:hover {
    background-color: ${props => props.active ? '#28346c' : 'rgba(230, 233, 236, 0.9)'};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const BackgroundCategoryCard = styled(CategoryCard)`
  background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 
    8px 8px 20px rgba(244, 116, 36, 0.25), /* Subtle orange flare bottom-right */
    0 3px 10px rgba(0, 0, 0, 0.2);         /* Soft original shadow */
  transition: transform 0.3s, box-shadow 0.3s;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
`;

const BackgroundJobItem = styled(JobItem)`
  color: #28346c;
  font-weight: 500;
`;

const BackgroundReadMoreLink = styled(ReadMoreLink)`
  color: #28346c;
  font-weight: 700;
  
  &:hover {
    color: #3498db;
    text-decoration: underline;
  }
`;

// Styled component wrapper
const EnhancedHeroWrapper = styled.div`
  position: relative;
  z-index: 30; /* Increased from 10 to 30 to ensure dropdowns stay on top of everything */
  
  /* This ensures all children dropdown elements inherit the high z-index */
  & > * {
    position: relative;
    z-index: inherit;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

// State abbreviation to full state name mapping (for consistency with JobListings)
const STATE_NAMES = {
  "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", 
  "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware",
  "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", 
  "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", 
  "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", 
  "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", 
  "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", 
  "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", 
  "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", 
  "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina", 
  "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", 
  "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", 
  "WI": "Wisconsin", "WY": "Wyoming", "DC": "District of Columbia"

  
};



const Home = () => {



  const [activeTab, setActiveTab] = useState('nursing');
  const navigate = useNavigate();
  
  // Filter states
  const [jobTitleSearch, setJobTitleSearch] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [activeFilter, setActiveFilter] = useState(null);
  
  // States for storing job data and filter options
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    jobTitles: [],
    states: []
  });

  // Store the fetched jobs to count how many times each title appears
  const [jobsData, setJobsData] = useState([]);
  
  // Fetch job data to extract real filter options
  useEffect(() => {
    const fetchJobData = async () => {
      try {
        setLoading(true);
        const jobs = await salesforceApi.getJobs();
        setJobsData(jobs || []);
        extractFilterOptions(jobs || []);
      } catch (err) {
        console.error('Error fetching job data for filters:', err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchJobData();
  }, []);
  
  // Extract unique values for filter options - similar to JobListings
  const extractFilterOptions = (jobsData) => {
    if (!jobsData || jobsData.length === 0) return;
    
    const options = {
      jobTitles: new Set(),
      states: new Set()
    };
    
    jobsData.forEach(job => {
      // Extract job titles
      if (job.JobTitlen) {
        options.jobTitles.add(job.JobTitlen);
      } else if (job.VMS_Job_Title__c) {
        options.jobTitles.add(job.VMS_Job_Title__c);
      } else if (job.AVTRRT__Job_Title__c) {
        options.jobTitles.add(job.AVTRRT__Job_Title__c);
      } else if (job.Job_Title__c) {
        options.jobTitles.add(job.Job_Title__c);
      } else {
        const orderType = job.vms_order_type__c || job.Order_Type || '';
        const specialty = job.Specialityn || job.Specialty || '';
        const title = job.JobTitlen || '';
        if (orderType || specialty || title) {
          const compositeTitle = `${orderType} ${specialty} ${title}`.trim();
          if (compositeTitle) {
            options.jobTitles.add(compositeTitle);
          }
        }
      }
      
      // Extract states
      if (job.State) {
        options.states.add(job.State);
      }
    });
    
    // Store raw arrays; sorting in getFilteredOptions for jobTitles.
    setFilterOptions({
      jobTitles: Array.from(options.jobTitles),
      states: Array.from(options.states).sort() // States remain alphabetical
    });
  };
  
  // Helper to get how often a given title appears in the job data
  const countJobsForTitle = (title) => {
    return jobsData.filter(job => {
      if (job.JobTitlen === title) return true;
      if (job.VMS_Job_Title__c === title) return true;
      if (job.AVTRRT__Job_Title__c === title) return true;
      if (job.Job_Title__c === title) return true;
      // Check for a composite title
      const orderType = job.vms_order_type__c || job.Order_Type || '';
      const specialty = job.Specialityn || job.Specialty || '';
      const t = job.JobTitlen || '';
      const compositeTitle = `${orderType} ${specialty} ${t}`.trim();
      return compositeTitle === title;
    }).length;
  };
  
  // Get state display name
  const getStateDisplayName = (stateAbbr) => {
    return STATE_NAMES[stateAbbr] || stateAbbr;
  };
  
  // Get filtered options for each filter type
  const getFilteredOptions = (filterType) => {
    if (filterType === 'jobTitle') {
      // Filter out titles by substring, then sort by (count desc, alpha)
      const filtered = filterOptions.jobTitles.filter(title =>
        title.toLowerCase().includes(jobTitleSearch.toLowerCase())
      );
      return filtered.sort((a, b) => {
        const countA = countJobsForTitle(a);
        const countB = countJobsForTitle(b);
        if (countB !== countA) return countB - countA; // Descending by count
        return a.localeCompare(b); // Tie-break alphabetical
      });
    } else if (filterType === 'state') {
      return filterOptions.states.filter(stateAbbr => {
        const stateName = getStateDisplayName(stateAbbr);
        return (
          stateName.toLowerCase().includes(stateSearch.toLowerCase()) ||
          stateAbbr.toLowerCase().includes(stateSearch.toLowerCase())
        );
      });
    }
    return [];
  };
  
  // Filter functions
  const handleFilterFocus = (filter) => {
    setActiveFilter(filter);
  };
  
  const handleFilterBlur = () => {
    setTimeout(() => {
      setActiveFilter(null);
    }, 200); // Increased timeout for better UX
  };
  
  const handleSearch = () => {
    const params = new URLSearchParams();
    
    // Add job title if selected
    if (jobTitleSearch) {
      params.append('title', jobTitleSearch);
    }
    
    // Add state parameter - use the actual state abbreviation from the data
    if (stateSearch) {
      // If stateSearch is a full state name, we need to get the abbreviation
      // If it's already an abbreviation, use it directly
      const isAbbreviation = filterOptions.states.includes(stateSearch);
      
      if (isAbbreviation) {
        params.append('state', stateSearch);
      } else {
        // Find the abbreviation for the state name
        const stateAbbr = Object.keys(STATE_NAMES).find(
          abbr => STATE_NAMES[abbr].toLowerCase() === stateSearch.toLowerCase()
        );
        
        if (stateAbbr) {
          params.append('state', stateAbbr);
        }
      }
    }
    
    // Add sort by recent for better default results
    params.append('sort', 'recent');
    
    // Navigate to jobs page with filters
    navigate(`/jobs?${params.toString()}`);
  };

  return (
    <div>

<div>
<VisuallyHidden>Capstone – Your Trusted Partner in Healthcare Staffing</VisuallyHidden>
      
      {/* Wrap EnhancedHeroSection styled component for z-index */}
      <EnhancedHeroWrapper>
        <EnhancedHeroSection
          jobTitleSearch={jobTitleSearch}
          setJobTitleSearch={setJobTitleSearch}
          stateSearch={stateSearch}
          setStateSearch={setStateSearch}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          handleFilterFocus={handleFilterFocus}
          handleFilterBlur={handleFilterBlur}
          handleSearch={handleSearch}
          loading={loading}
          getFilteredOptions={getFilteredOptions}
          getStateDisplayName={getStateDisplayName}
        />
      </EnhancedHeroWrapper>
      </div>

      <Section>
        <Container>
          <SectionTitle2 to="/why-capstone" onClick={() => window.scrollTo(0, 0)}>Why choose Capstone?</SectionTitle2>
          <FeaturesGrid>
            <FeatureCard>
              <FeatureIcon>
                <FaUserFriends />
              </FeatureIcon>
              <FeatureTitle>Personalized Approach</FeatureTitle>
              <FeatureDescription>
                We take the time to understand your unique requirements, tailoring our solutions to meet your specific needs and goals.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureIcon>
                <FaTrophy />
              </FeatureIcon>
              <FeatureTitle>Extensive Expertise</FeatureTitle>
              <FeatureDescription>
                With 20+ years of experience in the healthcare staffing industry, we bring a wealth of knowledge and expertise to the table.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureIcon>
                <FaHandshake />
              </FeatureIcon>
              <FeatureTitle>Premier Connections</FeatureTitle>
              <FeatureDescription>
                When you join Capstone, our professional job network is your network. Our recruitment agency connects you with leading Healthcare Organizations, offering access to more opportunities than ever before.
              </FeatureDescription>
            </FeatureCard>

            <TaglineCard>
              <Tagline>
                <DesktopTagline>
                  Work Rewardingly!<TrademarkSymbol>™</TrademarkSymbol>
                </DesktopTagline>
                
                <MobileTagline>
                  <MobileSvg className="left" />
                  <MobileTaglineWord>Work</MobileTaglineWord>
                  <MobileTaglineWord>Rewardingly!<TrademarkSymbol>™</TrademarkSymbol></MobileTaglineWord>
                  <MobileSvg className="right" />
                  <TaglineSubtext>Connect with Capstone Health</TaglineSubtext>
                </MobileTagline>
              </Tagline>
              
              {/* Only show this on desktop */}
              <DesktopTagline>
                <TaglineSubtext>Connect with Capstone Health</TaglineSubtext>
              </DesktopTagline>
            </TaglineCard>
          </FeaturesGrid>
        </Container>
      </Section>

      {/* Google Reviews Component */}
      <GoogleReviews />

      {/* Background image section rendering for both tabs */}
      <BackgroundImageSection>
        <BackgroundContainer>
          <BackgroundSectionTitle>Here's some of what we offer:</BackgroundSectionTitle>
          
          <TabContainer>
            <BackgroundTab 
              active={activeTab === 'nursing'} 
              onClick={() => setActiveTab('nursing')}
            >
              Nurses
            </BackgroundTab>
            <BackgroundTab 
              active={activeTab === 'allied'} 
              onClick={() => setActiveTab('allied')}
            >
              Allied Health
            </BackgroundTab>
          </TabContainer>
          
          {activeTab === 'nursing' && (
            <CategoriesContainer>
              <BackgroundCategoryCard>
                <CategoryTitle>Nursing</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Medical Surgical Nurse (Med Surg)</BackgroundJobItem>
                  <BackgroundJobItem>Intensive Care Nurse (ICU Nurse)</BackgroundJobItem>
                  <BackgroundJobItem>Neonatal Intensive Care Nurse (NICU Nurse)</BackgroundJobItem>
                  <BackgroundJobItem>Telemetry Nurse</BackgroundJobItem>
                  <BackgroundJobItem>Psychiatric Nurse</BackgroundJobItem>
                  <BackgroundJobItem>Emergency Room Nurse (ER Nurse)</BackgroundJobItem>
                  <BackgroundJobItem>Labor & Delivery Nurse (L&D Nurse)</BackgroundJobItem>
                  <BackgroundJobItem>Operating Room Nurse (OR Nurse)</BackgroundJobItem>
                  <BackgroundJobItem>Surgical Technologist (Surgical Tech)</BackgroundJobItem>
                  <BackgroundJobItem>Sterile Processing Tech (SPT)</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/nursing" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
              
              <BackgroundCategoryCard>
                <CategoryTitle>Long-Term Care</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Long-Term Care (RN)</BackgroundJobItem>
                  <BackgroundJobItem>Licensed Practical Nurse (LPN)</BackgroundJobItem>
                  <BackgroundJobItem>Licensed Vocational Nurse (LVN)</BackgroundJobItem>
                  <BackgroundJobItem>Certified Nursing Assistant (CNA)</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/long-term-care" onClick={() => window.scrollTo(0, 0)}>Read more  →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
              
              <BackgroundCategoryCard>
                <CategoryTitle>Cath Lab</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Cath Lab RN</BackgroundJobItem>
                  <BackgroundJobItem>Cath Lab LPN</BackgroundJobItem>
                  <BackgroundJobItem>Cardiac Cath Lab Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Cardiovascular Technologist</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/cath-labs" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
            </CategoriesContainer>
          )}
          
          {activeTab === 'allied' && (
            <CategoriesContainer>
              <BackgroundCategoryCard>
                <CategoryTitle>Cardiopulmonary</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Respiratory Therapist (RRT)</BackgroundJobItem>
                  <BackgroundJobItem>Polysomnographer</BackgroundJobItem>
                  <BackgroundJobItem>EEG Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Anesthesia Technician</BackgroundJobItem>
                  <BackgroundJobItem>EKG Technologist</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/cardiopulmonary" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
              
              <BackgroundCategoryCard>
                <CategoryTitle>Imaging</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Cardiovascular Technologist</BackgroundJobItem>
                  <BackgroundJobItem>CT Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Echo Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Interventional Radiologist</BackgroundJobItem>
                  <BackgroundJobItem>Mammography Technologist</BackgroundJobItem>
                  <BackgroundJobItem>MRI Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Ultrasound Technologist</BackgroundJobItem>
                  <BackgroundJobItem>Vascular Tech</BackgroundJobItem>
                  <BackgroundJobItem>X-Ray Technologist</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/imaging" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
              
              <BackgroundCategoryCard>
                <CategoryTitle>Laboratory</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Medical Technologist (MT)</BackgroundJobItem>
                  <BackgroundJobItem>Medical Lab Technician (MLT)</BackgroundJobItem>
                  <BackgroundJobItem>Clinical Lab Scientist (CLS)</BackgroundJobItem>
                  <BackgroundJobItem>Cytotechnologist</BackgroundJobItem>
                  <BackgroundJobItem>Histotechnologist</BackgroundJobItem>
                  <BackgroundJobItem>Pathology Assistant</BackgroundJobItem>
                  <BackgroundJobItem>Phlebotomists</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/laboratory" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
              
              <BackgroundCategoryCard>
                <CategoryTitle>Therapy</CategoryTitle>
                <JobsList>
                  <BackgroundJobItem>Physical Therapist (PT)</BackgroundJobItem>
                  <BackgroundJobItem>Physical Therapist Assistant (PTA)</BackgroundJobItem>
                  <BackgroundJobItem>Occupational Therapist (OT)</BackgroundJobItem>
                  <BackgroundJobItem>Occupational Therapist Assistant (OTA/COTA)</BackgroundJobItem>
                  <BackgroundJobItem>Speech Language Pathologist (SLP)</BackgroundJobItem>
                  <BackgroundJobItem>Pharmacy Technician</BackgroundJobItem>
                  <BackgroundJobItem>Social Workers</BackgroundJobItem>
                </JobsList>
                <BackgroundReadMoreLink to="/therapy" onClick={() => window.scrollTo(0, 0)}>Read more →</BackgroundReadMoreLink>
              </BackgroundCategoryCard>
            </CategoriesContainer>
          )}
        </BackgroundContainer>
      </BackgroundImageSection>
    </div>
  );
};

export default Home;