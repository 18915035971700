// src/pages/JobDetails.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import salesforceApi from '../services/salesforceApi';
import { FaFacebookSquare, FaLinkedin, FaEnvelope, FaSms } from 'react-icons/fa';
import { formatSpecialtyJobTitle } from '../utils/formatting';


const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #28346c;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainColumn = styled.div`
  flex: 1;
`;

const SidebarColumn = styled.div`
  width: 250px;
  margin-left: 2rem;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 1.5rem;
  }
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MainContent = styled.div`
  flex: 1;
`;

const JobHeader = styled.div`
  margin-bottom: 1rem;
  padding: 1.5rem 2rem;
  background-color: #28346c;
  border-radius: 1rem;
  box-shadow: 0 3px 10px rgba(40, 52, 108, 0.2);
  box-sizing: border-box;
`;

const JobTitleContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const JobTitle = styled.h1`
  margin: 0 0 0.75rem 0;
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
`;

const JobMetaGroup = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    width: 100%;
  }
`;

const PostedDate = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  
  @media (max-width: 768px) {
    text-align: left;
    width: 100%;
  }
`;

const MetaSeparator = styled.span`
  margin: 0 0.5rem;
  color: rgba(255, 255, 255, 0.5);
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

const JobLocation = styled.div`
  font-size: 1.1rem;
  color: white;
  font-weight: 500;
`;

const JobId = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
  
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const JobDetailsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #28346c;
  border-radius: 2rem;
  box-shadow: 0 2px 6px rgba(40, 52, 108, 0.2);
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 1.5rem;
    gap: 0.75rem;
  }
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }
`;

const DetailLabel = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  margin-right: 0.5rem;
`;

const DetailValue = styled.span`
  font-size: 1rem;
  color: white;
  font-weight: 500;
`;

const JobDescription = styled.div`
  line-height: 1.6;
  margin: 2rem 0;
  color: #2c3e50;

  h2, h3, h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #2c3e50;
  }

  p {
    margin-bottom: 1rem;
  }

  ul, ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
`;

const MapContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    margin-top: 1rem; /* Reduced from 2rem to 1rem (50% reduction) */
  }
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 200px;
  border: none;
`;

const MapLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
`;

// Mobile Action Bar - For the action buttons (Apply/Share) on mobile
const MobileActionBar = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 0rem 0 2.5rem;
  }
`;

// Second mobile action bar specifically for after description
const MobileActionBarBottom = styled(MobileActionBar)`
  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 1.25rem; /* Reduced from 2.5rem to 1.25rem (50% reduction) */
  }
`;

const ShareSection = styled.div`
  padding: 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.75rem;
  background-color: #f8f9fb;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    display: none; /* Hide on mobile since we have it in the MobileActionBar */
  }
`;

const ShareTitle = styled.h3`
  margin: 0 0 .75rem 0;
  color: #28346c;
  font-size: 0.9rem;
  font-weight: 600;
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  justify-content: center;
`;

const ShareButton = styled.a`
  color: #28346c;
  font-size: 1.5rem;
  transition: color 0.2s, transform 0.2s;
  
  &:hover {
    color: #2980b9;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    font-size: 1.8rem; /* Slightly larger icons on mobile */
  }
`;

const ApplyButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28346c;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(40, 52, 108, 0.4);
  margin: 2.5rem auto;
  width: 80%;
  max-width: 400px;
  
  &:hover {
    background-color: #1c2654;
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(40, 52, 108, 0.5);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 6px rgba(40, 52, 108, 0.3);
  }
  
  @media (max-width: 768px) {
    display: none; /* Hide this button on mobile */
  }
`;

const TopApplyButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28346c;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(40, 52, 108, 0.3);
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 2.75rem;
  
  &:hover {
    background-color: #1c2654;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(40, 52, 108, 0.4);
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 4px rgba(40, 52, 108, 0.2);
  }
  
  @media (max-width: 768px) {
    /* For the sidebar version, hide it on mobile */
    /* The MobileActionBar version will use these styles */
    &.sidebar-button {
      display: none;
    }
    
    /* Make the button larger and thicker for mobile */
    padding: 1.2rem 2.5rem;
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 1rem;
    margin-top: 1.375rem; /* Reduced by 50% from 2.75rem */
  }
`;

const MobileShareContainer = styled.div`
  order: 2; /* This makes the Share section second on mobile */
`;

const MobileApplyWrapper = styled.div`
  order: 1; /* This makes the Apply button first on mobile */
`;

// Same LoadingState style as in JobListings
const LoadingState = styled.div`
  text-align: center;
  padding: 3rem;
  font-size: 1.1rem;
  color: #7f8c8d;
`;

// Loading animation components (same as JobListings)
const LoadingDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

const LoadingDot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: #f47424;
  border-radius: 50%;
  animation: bounce-pulse 1.4s infinite ease-in-out;
  animation-delay: ${props => props.delay || '0s'};

  @keyframes bounce-pulse {
    0%, 80%, 100% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const ErrorState = styled.div`
  text-align: center;
  padding: 2rem;
  color: #e74c3c;
  border: 1px solid #e74c3c;
  border-radius: 8px;
`;

/* Commented out Debug Panel
const DebugPanel = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  overflow-x: auto;
`;
*/

/**
 * Lightly formats the default Webportaldescriptions text into paragraphs,
 * bullet points, and disclaimers.
 * Assumes the same string pattern is always provided.
 */
function formatWebportalDescriptions(text) {
  let output = text;

  // 1) Insert bullet points under "Our travelers enjoy..."
  output = output.replace(
    /Our travelers enjoy a range of traditional and modern benefits, including:/,
    'Our travelers enjoy a range of traditional and modern benefits, including:<br/><ul>' +
      '<li>Dedicated Recruiter</li>' +
      '<li>$1,000 Unlimited Referral Bonus</li>' +
      '<li>Medical, Dental, and Vision Insurance</li>' +
      '<li>Complementary Life Insurance</li>' +
      '<li>401(k)</li>' +
      '<li>Lodging and Meals &amp; Incidental Reimbursement (with qualified tax home)</li>' +
      '<li>Licensure/Certification Reimbursement</li>' +
      '<li>Voluntary Insurance Benefits</li>' +
      '<li>Completion Bonus</li>' +
      '<li>Equal Employment Opportunity</li>' +
      '<li>And more!</li>' +
    '</ul><br/>'
  );

  // 2) Wrap the *Estimated pay... disclaimer in <p> with a line break before it
  output = output.replace(
    /\*Estimated pay and benefits package based.*?etc\./,
    '<br/><br/><p>*Estimated pay and benefits package based on bill rate at the time the job was posted. ' +
    'Bill rates can change without notice. Exact pay and benefits packages may vary based on several factors, ' +
    'including, but not limited to, guaranteed hours, travel distance, demand, experience, eligibility, etc.</p>'
  );

  // 3) Insert simple paragraph breaks around the earlier sentences, if you like:
  //    (Replace a period + space with a period + double line break)
  output = output.replace(/\. /g, '.<br/><br/>');

  return output;
}

// Format days open text for posting date
const formatDaysOpenText = (daysOpen) => {
  if (daysOpen === undefined || daysOpen === null) return '';
  
  const days = parseInt(daysOpen, 10);
  if (isNaN(days)) return '';
  
  if (days === 0) return 'Today';
  return days === 1 ? '1 day ago' : `${days} days ago`;
};

// Returns a string combining Jobdescriptionrich and Webportaldescriptions
// and applies light formatting to the latter if present.
const getJobDescription = (job) => {
  const { Jobdescriptionrich, Webportaldescriptions } = job;

  if (Jobdescriptionrich && Webportaldescriptions) {
    return (
      `${Jobdescriptionrich}<br/><br/>` +
      formatWebportalDescriptions(Webportaldescriptions)
    );
  } else if (Jobdescriptionrich) {
    return Jobdescriptionrich;
  } else if (Webportaldescriptions) {
    return formatWebportalDescriptions(Webportaldescriptions);
  }
  return 'No description available';
};

const JobDetails = () => {
  const { jobId } = useParams();
  const location = useLocation();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // Commented out debug state
  // const [showDebug, setShowDebug] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const jobData = await salesforceApi.getJobDetails(jobId);
        console.log('Job data received:', jobData);
        setJob(jobData);
        setError('');
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError('Failed to load job details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  // Get the referrer URL if it exists in the URL parameters
  const searchParams = new URLSearchParams(location.search);
  const fromParam = searchParams.get('from') || '';
  
  // Construct the back URL, either with the 'from' parameter or with the current search params
  const backUrl = fromParam 
    ? `/jobs${fromParam}` 
    : (location.state?.from || `/jobs${location.search}`);
    
  // Get current URL for sharing
  const currentUrl = window.location.href;
  
  // Prepare share text
  const getShareText = () => {
    if (!job) return 'Check out this healthcare job!';
    
    let jobTitle;
    
    if (job.Specialityn && job.JobTitlen) {
      jobTitle = formatSpecialtyJobTitle(job.Specialityn, job.JobTitlen);
    } else if (job.Order_Type && job.Specialityn) {
      jobTitle = `${job.Order_Type} ${job.Specialityn}`.trim();
    } else {
      jobTitle = job.JobTitlen || 'Healthcare Job';
    }
      
    const location = job.city && job.State ? `in ${job.city}, ${job.State}` : '';
    
    return `Check out this ${jobTitle} ${location} at Capstone Health Jobs!`;
  };

  return (
    <div>
      <PageContainer>
        <BackLink to={backUrl}>&larr; Back to jobs</BackLink>

        {/* Commented out debug button
        <button 
          onClick={() => setShowDebug(!showDebug)} 
          style={{ float: 'right', padding: '4px 8px', fontSize: '0.8rem' }}
        >
          {showDebug ? 'Hide Debug' : 'Show Debug'}
        </button>
        */}

        {loading ? (
          <LoadingState>
            <LoadingDots>
              <LoadingDot delay="0s" />
              <LoadingDot delay="0.2s" />
              <LoadingDot delay="0.4s" />
            </LoadingDots>
          </LoadingState>
        ) : error ? (
          <ErrorState>{error}</ErrorState>
        ) : job ? (
          <>
            {/* Commented out debug panel
            {showDebug && (
              <DebugPanel>
                <div>Available Fields:</div>
                {Object.keys(job).map(key => (
                  <div key={key}>{key}: {JSON.stringify(job[key])}</div>
                ))}
              </DebugPanel>
            )}
            */}

            <ContentWrapper>
              {/* Main Column: Job Header + Details */}
              <MainColumn>

              <JobHeader>
                  <JobTitleContainer>
                    <JobTitle>
                      {job.Specialityn && job.JobTitlen 
                        ? formatSpecialtyJobTitle(job.Specialityn, job.JobTitlen)
                        : (job.Order_Type && job.Specialityn)
                          ? `${job.Order_Type} ${job.Specialityn} ${job.JobTitlen || ''}`.trim()
                          : job.JobTitlen || 'Job Title Not Available'}
                    </JobTitle>
                    <LocationContainer>
                      {job.city && job.State && (
                        <JobLocation>in {job.city}, {job.State}</JobLocation>
                      )}
                      <JobMetaGroup>
                        <JobId>Job ID: {job.Client_Job_ID || job.JobNum || 'N/A'}</JobId>
                        {formatDaysOpenText(job.Days_Open__c) && (
                          <>
                            <MetaSeparator>|</MetaSeparator>
                            <PostedDate>{formatDaysOpenText(job.Days_Open__c)}</PostedDate>
                          </>
                        )}
                      </JobMetaGroup>
                    </LocationContainer>
                  </JobTitleContainer>
                </JobHeader>
                
                <ContentLayout>
                  <MainContent>
                    {/* Job Details Row - no dividers, just a small gap */}
                    <JobDetailsRow>
                      {job.Shiftn && (
                        <DetailItem>
                          <DetailLabel>Shift:</DetailLabel>
                          <DetailValue>{job.Shiftn}</DetailValue>
                        </DetailItem>
                      )}

                      {job.Contract_Length && (
                        <DetailItem>
                          <DetailLabel>Contract:</DetailLabel>
                          <DetailValue>{job.Contract_Length}</DetailValue>
                        </DetailItem>
                      )}

                      {job.CalculatedPayRange && (
                        <DetailItem>
                          <DetailLabel>Pay:</DetailLabel>
                          <DetailValue>{job.CalculatedPayRange}</DetailValue>
                        </DetailItem>
                      )}
                    </JobDetailsRow>
                    
                    {/* Mobile Action Bar - only visible on mobile */}
                    <MobileActionBar>
                      <MobileApplyWrapper>
                        <TopApplyButton to={`/apply/${job.Record_Id}`}>Apply Now</TopApplyButton>
                      </MobileApplyWrapper>
                      
                      <MobileShareContainer>
                        <ShareTitle>Share This Job</ShareTitle>
                        <ShareButtonsContainer>
                          <ShareButton 
                            href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on Facebook"
                          >
                            <FaFacebookSquare />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on LinkedIn"
                          >
                            <FaLinkedin />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`mailto:?subject=${encodeURIComponent(`Job Opportunity: ${getShareText()}`)}&body=${encodeURIComponent(`${getShareText()}\n\n${currentUrl}`)}`}
                            aria-label="Share via Email"
                          >
                            <FaEnvelope />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`sms:?body=${encodeURIComponent(`${getShareText()} ${currentUrl}`)}`}
                            aria-label="Share via Text Message"
                          >
                            <FaSms />
                          </ShareButton>
                        </ShareButtonsContainer>
                      </MobileShareContainer>
                    </MobileActionBar>

                    <JobDescription
                      dangerouslySetInnerHTML={{ __html: getJobDescription(job) }}
                    />
                    
                    {/* Second Mobile Action Bar (bottom) - only visible on mobile */}
                    <MobileActionBarBottom>
                      <MobileApplyWrapper>
                        <TopApplyButton to={`/apply/${job.Record_Id}`}>Apply Now</TopApplyButton>
                      </MobileApplyWrapper>
                      
                      <MobileShareContainer>
                        <ShareTitle>Share This Job</ShareTitle>
                        <ShareButtonsContainer>
                          <ShareButton 
                            href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on Facebook"
                          >
                            <FaFacebookSquare />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Share on LinkedIn"
                          >
                            <FaLinkedin />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`mailto:?subject=${encodeURIComponent(`Job Opportunity: ${getShareText()}`)}&body=${encodeURIComponent(`${getShareText()}\n\n${currentUrl}`)}`}
                            aria-label="Share via Email"
                          >
                            <FaEnvelope />
                          </ShareButton>
                          
                          <ShareButton 
                            href={`sms:?body=${encodeURIComponent(`${getShareText()} ${currentUrl}`)}`}
                            aria-label="Share via Text Message"
                          >
                            <FaSms />
                          </ShareButton>
                        </ShareButtonsContainer>
                      </MobileShareContainer>
                    </MobileActionBarBottom>

                    <ApplyButton to={`/apply/${job.Record_Id}`}>Apply Now</ApplyButton>
                  </MainContent>
                </ContentLayout>
              </MainColumn>
              
              {/* Sidebar Column */}
              <SidebarColumn>
                {/* 1. Share links */}
                <ShareSection>
                  <ShareTitle>Share This Job</ShareTitle>
                  <ShareButtonsContainer>
                    <ShareButton 
                      href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Share on Facebook"
                    >
                      <FaFacebookSquare />
                    </ShareButton>
                    
                    <ShareButton 
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Share on LinkedIn"
                    >
                      <FaLinkedin />
                    </ShareButton>
                    
                    <ShareButton 
                      href={`mailto:?subject=${encodeURIComponent(`Job Opportunity: ${getShareText()}`)}&body=${encodeURIComponent(`${getShareText()}\n\n${currentUrl}`)}`}
                      aria-label="Share via Email"
                    >
                      <FaEnvelope />
                    </ShareButton>
                    
                    <ShareButton 
                      href={`sms:?body=${encodeURIComponent(`${getShareText()} ${currentUrl}`)}`}
                      aria-label="Share via Text Message"
                    >
                      <FaSms />
                    </ShareButton>
                  </ShareButtonsContainer>
                </ShareSection>
                
                {/* 2. Apply Button - hidden on mobile with sidebar-button class */}
                <TopApplyButton to={`/apply/${job.Record_Id}`} className="sidebar-button">Apply Now</TopApplyButton>
                
                {/* 3. Location Map */}
                {job.city && job.State && (
                  <MapContainer>
                    <MapLink 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${job.city}, ${job.State}`)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MapIframe
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=${encodeURIComponent(`${job.city}, ${job.State}`)}`}
                        title={`Map of ${job.city}, ${job.State}`}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </MapLink>
                  </MapContainer>
                )}
              </SidebarColumn>
            </ContentWrapper>
          </>
        ) : (
          <ErrorState>Job not found.</ErrorState>
        )}
      </PageContainer>
    </div>
  );
};

export default JobDetails;